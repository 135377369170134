import { getPlatform } from '../../../../utils/getPlatform';
import {
	EVENT_CONTACT_VENDOR,
	EVENT_CONTACT_VENDOR_INITIATED,
} from '../../../vendor-recommendation-module/utils/tracking/vrmTrackingContants';
import { isOnStorefront } from '../utils/isOnStorefront';

const window = require('global/window');

const onStorefront = isOnStorefront();
const STOREFRONT_STORE_NAME = 'storefrontStore';
let vendor;

if (window.ReactOnRails) {
	vendor = onStorefront
		? window.ReactOnRails.stores().has(STOREFRONT_STORE_NAME) &&
			window.ReactOnRails.getStore(STOREFRONT_STORE_NAME).getState().vendor
		: null;
} else {
	vendor = onStorefront ? window.__INITIAL_STATE__.vendor : null;
}

const purchaseMapping = {
	FREEMIUM: 'unpaid storefront',
	PAID: 'paid storefront',
};

const defaults = {
	product: 'marketplace',
	platform: getPlatform(),

	sourcePage: onStorefront
		? purchaseMapping[vendor.vendorRaw.purchaseStatus]
		: 'category results',
};

const storefront = {
	mediaSummaryCount: 'storefront.mediaSummary.total',
	partnerAccountId: 'storefront.accountId',
	partnerAccountStatus: 'storefront.accountStatus',
	partnerVendorId: 'storefront.vendorId',
	photoSummaryCount: 'storefront.photoSummary.total',
	vendorAddress: 'storefront.location.address.address1',
	vendorBestOfWeddings: 'storefront._bow',
	vendorCategoryCode: 'storefront._categoryCode',
	vendorCity: 'storefront.location.address.city',
	vendorClaimedStatus: 'storefront.claimedStatus',
	vendorDisplayId: 'storefront.displayId',
	vendorId: 'storefront.id',
	vendorMarketCode: 'storefront.marketCode',
	vendorName: 'storefront.name',
	vendorPriceTier: 'storefront._price',
	vendorPurchaseStatus: 'storefront.purchaseStatus',
	vendorReviewCount: 'storefront.reviewSummary.count',
	vendorState: 'storefront.location.address.state',
	vendorTransactions: 'storefront._transactional',
	vendorUrl: 'storefront.websiteUrl',
	vendorVendorTier: 'storefront.vendorTier',
	vendorzip: 'storefront.location.address.postalCode',
};

const wizard = {
	stepName: 'step.name',
	stepNumber: 'step.number',
};

const data = {
	rfqAboutWedding: 'data.body',
	rfqAdditionalEquipment: 'data._additionalServices',
	rfqBudget: 'data.budget.display',
	rfqEmail: 'data.emailAddress',
	rfqEndTime: 'data._endTime',
	rfqEquipmentAndServices: 'data._equipmentAndServices',
	rfqEvents: 'data._events',
	rfqFirstName: 'data.firstName',
	rfqFlexibleWeddingDate: 'data._flexibleWeddingDate',
	rfqGuestCount: 'data.guest_count.display',
	rfqHourCount: 'data.numberOfHours.display',
	rfqHowManyNeedServices: 'data.howManyNeedServices.display',
	rfqLastName: 'data.lastName',
	rfqLocation: 'data.location.display',
	rfqStartTime: 'data.weddingTime.display',
	rfqTourSchedule: 'data.tourSchedule.display',
	rfqVenuePicked: 'data.venue_picker.has_venue',
	rfqWeddingDate: 'data.xo_date_picker.weddingDate',
	rfqWhoNeedsServices: 'data._whoNeedsServices',
	test: 'isTestStorefront',
	savedRFQ: 'isSaved',
};

const rfqFlowInteraction = (selection) => ({
	name: 'RFQ Flow Interaction',
	properties: { ...data, ...storefront, ...wizard },
	defaults: { selection, ...defaults },
});

const contactVendor = (property) => ({
	name: EVENT_CONTACT_VENDOR,
	properties: {
		vendorAddress2: 'storefront.location.address.address2',
		vendorFormattedPhoneNumber: 'storefront._phoneNumber',
		leadFormType: 'leadFormType',
		additionalVendor: 'data.additionalVendor',
		weddingVisionIncluded: 'data.bluecard',
		hasWeddingVision: 'data.hasBlueCard',
		...property,
		vendorReferredMarketCode: 'vendorReferredMarketCode',
		...storefront,
	},
	defaults: {
		phoneNumber: false,
		...defaults,
	},
});

const onStart = {
	events: [
		{
			name: EVENT_CONTACT_VENDOR_INITIATED,
			properties: {
				badges: 'badges',
				vendorAddress2: 'storefront.location.address.address2',
				vendorFormattedPhoneNumber: 'storefront._phoneNumber',
				leadFormType: 'leadFormType',
				additionalVendor: 'data.additionalVendor',
				vendorReferredMarketCode: 'vendorReferredMarketCode',
				...storefront,
			},
			defaults: {
				phoneNumber: false,
				...defaults,
			},
		},
		rfqFlowInteraction('initial'),
	],
};

const onNext = {
	events: [rfqFlowInteraction('next')],
};

const onBack = {
	events: [rfqFlowInteraction('back')],
};

const onFinish = {
	events: [
		rfqFlowInteraction('submit'),
		contactVendor({ conversationId: 'data.conversationId' }),
	],
};

const onClose = {
	events: [rfqFlowInteraction('Close')],
};

const onBulkInquire = {
	events: [contactVendor({ isVRT: 'data.isVRT' })],
};

const tracking = {
	onStart,
	onNext,
	onBack,
	onFinish,
	onClose,
	onBulkInquire,
};

export const payloads = {
	data,
	defaults,
	storefront,
	wizard,
};

export const events = {
	onStart,
	onNext,
	onBack,
	onFinish,
	onClose,
	onBulkInquire,
	all: tracking,
};
